// STRINGS (MESSAGES)
const TOO_SHORT = 'Item is Too Short!';
const TOO_LONG = 'Item is Too Long!';
const REQUIRED = 'This Field is Required!';
const EMAIL = 'Invalid Email';
const GENERIC_FIELD_ERROR = "Something's not right";

const URLS = {
  csrf: '/backend/csrf/',
  businesses: '/donate/businesses/',
  business_agreements: '/donate/business-agreements/',
  donations: '/donate/donations/',
  stories: '/donate/stories/',
  zipcodes: '/donate/zipcodes/',
};

export { TOO_SHORT, TOO_LONG, REQUIRED, EMAIL, GENERIC_FIELD_ERROR, URLS };
